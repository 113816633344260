import React, { useEffect, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import { Outlet, Link, useLocation } from "react-router-dom";
import AppDownload from '../appDownload/AppDownload';
import useDeviceDetect from '../../customHook/deviceDetect/useDeviceDetect';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import Marquee from "react-fast-marquee";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import FlatwareOutlinedIcon from '@mui/icons-material/FlatwareOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import SplitscreenOutlinedIcon from '@mui/icons-material/SplitscreenOutlined';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from './constHeader';
import { suboriginalKey } from './constHeader';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
import { 
  setMobileOpen,
  setAppBarColor,
  setAppBarLogo,
  setAppBarTopLogo,
  setAppBarPosition,
  setAppBarPositionTop,
  setAppBarWidthTheme3,
  setAppBarPositionTheme5,
  setAppLogoTheme2,
  setAppIconTheme2,
  setMenuPagePositionTop,
  setIsPromotionVisible,
  setIsActiveMarquee,
  setIsActiveMarqueeclick,
  setOpenOrder,
  setExpanded
} from '../../features/header/headerSlice';
import './Header.css';
const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};
const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});
const StyledFabMenu = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right:0,
  margin: '0 auto',
});
const StyledFabOrder = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  right: '15%',
  margin: '0 auto',
});
const StyledFabContact = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: '15%',
  margin: '0 auto',
});
const Header = ({getLocation, headermenu, alldata, themeData, allLocationNavigtion, loading,substoredEncryptedKey, mainTheme, webLocationLength},props) => {
  const res_id = getLocation?.eatstations_res_id;
  const { height, width } = useWindowDimensions();
  const windowWidth = width;
  const { isMobile, isTablet } = useDeviceDetect();
  const { window } = props;
  const currentUrl = useLocation();
  var lastPart = currentUrl.pathname.split("/").pop();
  
  const dispatch = useDispatch();
  const {
    mobileOpen,
    appBarColor,
    appBarLogo,
    appBarTopLogo,
    appBarPosition,
    appBarPositionTop,
    appBarWidthTheme3,
    appBarPositionTheme5,
    appLogoTheme2,
    appIconTheme2,
    menuPagePositionTop,
    isPromotionVisible,
    isActiveMarquee,
    isActiveMarqueeclick,
    openOrder,
    expanded,
  } = useSelector((state) => state.header);
  const{
    webmenuitemData1
  } = useSelector((state) => state.api)
  useEffect(() => {
    dispatch(setAppBarWidthTheme3('80%'));
    if (isMobile || isTablet) {
      dispatch(setAppBarPositionTop('103px'));
    } else {
      dispatch(setAppBarPositionTop('40px'));
    }
  }, [isMobile, isTablet, dispatch]);
  useEffect(() => {
    if (windowWidth < 600) {
      dispatch(setAppBarColor(themeData?.header_color));
      dispatch(setAppBarTopLogo('none'));
      dispatch(setAppBarLogo('block'));
    } else {
      dispatch(setAppBarColor('#00000075'));
    }
  }, [windowWidth, dispatch]);
  const promotionStatus = getLocation?.promotion_status;
  const togglePromotionVisibility = () => {
    dispatch(setIsPromotionVisible(!isPromotionVisible));
    dispatch(setIsActiveMarquee('in-active'));
    dispatch(setIsActiveMarqueeclick('in-active'));
    dispatch(setMenuPagePositionTop());
  };
  useEffect(() => {
    if (promotionStatus == 'active') {
      if (isActiveMarqueeclick == 'in-active') {
        dispatch(setIsActiveMarquee('in-active'));
      }else{
        dispatch(setIsActiveMarquee('active'));
      }
    }
    else if(promotionStatus == 'in-active'){
      dispatch(setIsActiveMarquee('in-active'));
    }
    else{
      dispatch(setIsActiveMarquee('in-active'));
    }
  },promotionStatus)
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  useEffect(() => {
    if (trigger) {
      dispatch(setAppBarColor(themeData?.header_color));
      dispatch(setAppBarTopLogo('none'));
      dispatch(setAppBarLogo('block'));
      if(mainTheme.layout == 2){
        dispatch(setAppIconTheme2('1.3em'));
        dispatch(setAppLogoTheme2('80px'));
      }
      dispatch(setAppBarPosition('fixed'));
      dispatch(setAppBarWidthTheme3('90%'));
      if (isMobile || isTablet) {
        dispatch(setAppBarPositionTop(isActiveMarquee == 'active' ? '40px' : '0px'));
        dispatch(setAppBarPositionTheme5(isActiveMarquee == 'active' ? '103px' : '63px'));
        dispatch(setMenuPagePositionTop(isActiveMarquee == 'active' ? '95px':'55px'))
      } else {
        dispatch(setAppBarPositionTop(isActiveMarquee == 'active' ? '40px' : '0px'));
        dispatch(setAppBarPositionTheme5(isActiveMarquee == 'active' ? '103px' : '63px'));
        dispatch(setMenuPagePositionTop(isActiveMarquee == 'active' ? '104px':'63px'))
      }
    } else {
      dispatch(setAppBarWidthTheme3('80%'));
      if (windowWidth < 600) {
        dispatch(setAppBarColor(themeData?.header_color));
        dispatch(setAppBarTopLogo('none'));
        dispatch(setAppBarLogo('block'));
      } else {
        dispatch(setAppBarColor('#00000075'));
        dispatch(setAppBarTopLogo('flex'));
        dispatch(setAppBarLogo('none'));
      }
      if(mainTheme.layout == 2){
        dispatch(setAppIconTheme2('1.5em'));
        dispatch(setAppLogoTheme2('auto'));
      }
      dispatch(setAppBarPosition('absolute'));
      if (isMobile || isTablet) {
        dispatch(setAppBarPositionTop(isActiveMarquee == 'active' ? '103px' : '63px'));
        dispatch(setAppBarPositionTheme5(isActiveMarquee == 'active' ? '103px' : '63px'));
        dispatch(setMenuPagePositionTop(isActiveMarquee == 'active' ? windowWidth < 600 ? '163px':'245px':'120px'))
      } else {
        dispatch(setAppBarPositionTop(isActiveMarquee == 'active' ? '40px' : '0px'));
        dispatch(setAppBarPositionTheme5(isActiveMarquee == 'active' ? '103px' : '63px'));
        dispatch(setMenuPagePositionTop(isActiveMarquee == 'active' ? '163px':'124px'))
      }
    }
  }, [trigger, isMobile, isTablet, isActiveMarquee, windowWidth, themeData, dispatch]);
  const handleClickOpenOrder = () => {
    dispatch(setOpenOrder(true));
  };
  const handleCloseOrder = () => {
    dispatch(setOpenOrder(false));
  };
  const handleDrawerToggle = () => {
    dispatch(setMobileOpen(!mobileOpen));
  };
  const handleChange = (panel) => (event, newExpanded) => {
    dispatch(setExpanded(newExpanded ? panel : false));
  };
  const [state, setState] = React.useState({
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let btn_order_now_urls;
  alldata?.order_now_urls?.map((urls)=>{
    btn_order_now_urls = urls?.url;
  })
 // console.log(webLocationLength);
  const drawer = (
    <Box>
      {['bottom'].map((anchor) => (
        <Box
          sx={{ textAlign: 'center',backgroundColor: mainTheme.layout == 3 ?'transparent':mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color :'',}} >
          {mainTheme.layout == 2 ? (
          <Box sx={{my:1}}>
            {alldata && alldata.user && (
              <Box sx={{display:'flex',alignItems:'center'}}>
                <Box sx={{mx:1}}>
                <>
                  {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                    <a href="#" onClick={mainTheme.layout == 2 ? handleDrawerToggle : toggleDrawer(anchor, false)}>
                      <img
                        //width={30}
                        src={`${alldata.user?.image}`}
                        alt={'Logo Not Found'}
                        loading="lazy"
                        style={{transition:'all 0.3s ease-in-out'}}
                        />
                    </a> 
                  ):(
                    <Link to={`/${allLocationNavigtion}#`} onClick={mainTheme.layout == 2 ? handleDrawerToggle : toggleDrawer(anchor, false)}>
                      <img
                        //width={30}
                        src={`${alldata.user?.image}`}
                        alt={'Logo Not Found'}
                        loading="lazy"
                        style={{transition:'all 0.3s ease-in-out'}}
                        />
                    </Link>
                  )}
                </>
                </Box>
                <Box sx={{mx:1,px:1,borderLeft:'0.4px solid #0000002e',textAlign:'center'}}>
                  <Typography sx={{color:`${themeData?.header_font_color}`,fontWeight:700,fontSize:'16px'}}>{alldata.user.name}</Typography>
                  <Typography sx={{fontSize:'12px',color:`${themeData?.header_font_color}`}}>Powered By Eatstations</Typography>
                </Box>
              </Box>
            )}
          </Box>
          ):null}
          {mainTheme.layout == 3 || mainTheme.layout == 5 ? (
            <Box sx={{height:'40px',backgroundColor:'transparent',position:'relative',display:'flex',justifyContent:'center'}}>
              <Box sx={{width:'70px',height:'70px',backgroundColor:'#fff',borderRadius:'50%',position:'absolute',top:'5px',display:'flex',alignContent:'center',justifyContent:'center',zIndex:1}}>
                {alldata && alldata.user ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img
                          width={65}
                          src={`${alldata.user?.image}`}
                          alt={'Logo Not Found'}
                          loading="lazy"
                          style={{transition:'all 0.3s ease-in-out'}}
                          />
                      </a> 
                    ):(
                      <Link to={`/${allLocationNavigtion}#`} sx={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img
                          width={65}
                          src={`${alldata.user?.image}`}
                          alt={'Logo Not Found'}
                          loading="lazy"
                          style={{transition:'all 0.3s ease-in-out'}}
                          />
                      </Link>
                    )}
                  </>
                ) : null}
              </Box>
            </Box>
          ) : null}
          {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
            <Box sx={{height:'40px',backgroundColor:'transparent',position:'relative',display:'flex',justifyContent:'center'}}>
              <Box sx={{width:'70px',height:'70px',backgroundColor:'#fff',borderRadius:'50%',position:'absolute',top:'5px',display:'flex',alignContent:'center',justifyContent:'center',zIndex:1}}>
                {alldata && alldata.user ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img
                          width={80}
                          src={`${alldata.user?.image}`}
                          alt={'Logo Not Found'}
                          loading="lazy"
                          style={{transition:'all 0.3s ease-in-out',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',borderRadius:'50%'}}
                          />
                      </a> 
                    ):(
                      <Link to={`/${allLocationNavigtion}#`} sx={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img
                          width={80}
                          src={`${alldata.user?.image}`}
                          alt={'Logo Not Found'}
                          loading="lazy"
                          style={{transition:'all 0.3s ease-in-out',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',borderRadius:'50%'}}
                          />
                      </Link>
                    )}
                  </>
                ):null}
              </Box>
            </Box>
          ) : null}
          <Divider />
          <List sx={{backgroundColor:`${themeData?.header_color}`,color:`${themeData?.header_font_color}`,borderRadius:'20px 20px 0px 0px',paddingTop:'30px'}}>
            {headermenu.map(headermenu => (
              <>
                {headermenu.headermenu_id == 100 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle : toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <CottageOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <CottageOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ): null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <CottageOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <CottageOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 1 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#gallery" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <CollectionsOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <CollectionsOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#gallery`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <CollectionsOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <CollectionsOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 2 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#Our_story" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <InfoOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null }
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <InfoOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Our_story`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <InfoOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <InfoOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 3 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#News_letter" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <FeedOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <FeedOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#News_letter`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <FeedOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <FeedOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 4 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#Our_blog" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <RateReviewOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <RateReviewOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Our_blog`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <RateReviewOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <RateReviewOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 5 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href="#Testimonials" style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <AddReactionOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <AddReactionOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Testimonials`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <AddReactionOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <AddReactionOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 6 && webLocationLength > 1 ? (
                  <Box key={headermenu.display_title}>
                    <SimpleTreeView sx={{paddingLeft:mainTheme.layout == 4 || mainTheme.layout == 1 && '16px',paddingRight:mainTheme.layout == 4 || mainTheme.layout == 1 && '16px'}} onClick={mainTheme.layout == 2 ? '':toggleDrawer(anchor, true)}>
                      <TreeItem itemId="grid" label={
                        <Typography
                        sx={{
                          fontFamily: `${themeData?.font_style}`,
                          fontSize: `${themeData?.font_size}px`,
                          fontWeight: 700,
                          textTransform: 'capitalize',
                        }}
                      >
                        {headermenu.display_title}
                      </Typography>
                      } 
                      className={mainTheme.layout == 4 || mainTheme.layout == 1 ? 'treeitemtheme4':''}
                        sx={{
                          textAlign:'start',
                          px: 1,
                          color: `${themeData?.header_font_color}`,
                          fontWeight: 700,
                          fontFamily: `${themeData?.font_style}`,
                          textTransform: 'capitalize',
                          fontSize: `${themeData?.font_size}px`,
                          backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                          }}>
                        {headermenu.data?.map((locationHeaderMenu) => (
                          <a href={`/${locationHeaderMenu.url}`} style={{ textDecoration: 'none' }}>
                            <Typography
                              sx={{
                                p: 1,
                                color: themeData?.header_font_color,
                                fontWeight: 700,
                                fontFamily: themeData?.font_style,
                                textTransform: 'capitalize',
                                fontSize: `${themeData?.font_size}px`,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <LocationOnOutlinedIcon sx={{ mr: 1 }} />
                              {locationHeaderMenu.title}
                            </Typography>
                          </a>
                        ))}
                      </TreeItem>
                    </SimpleTreeView>
                  </Box>
                ) : null}
                {headermenu.headermenu_id == 7 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href='#Reservation' style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <AirplaneTicketOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <AirplaneTicketOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Reservation`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <AirplaneTicketOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <AirplaneTicketOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 8 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href='#Slide_Banner' style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <SlideshowIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <SlideshowIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null }
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Slide_Banner`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <SlideshowIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <SlideshowIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 101 && res_id != '' && res_id ? (
                    <Link to={`/${allLocationNavigtion}/menu`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                      <ListItem key={headermenu} style={{padding:0}}>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                          <ListItemText>
                            <Typography
                            sx={{
                              px:1,
                              color:`${themeData?.header_font_color}`,
                              fontWeight:700,
                              fontFamily:`${themeData?.font_style}`,
                              textTransform:'capitalize',
                              fontSize:`${themeData?.font_size}px`,
                              display:'flex',
                              alignItems:'center',
                              backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                            }}
                            >
                              {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                    <RestaurantMenuOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <RestaurantMenuOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </Link>
                ) : null}
                {headermenu.headermenu_id == 9 ? (
                  <>
                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                      <a href='#Split_View' style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                      <SplitscreenOutlinedIcon sx={{mr:1}}/>
                                      {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <SplitscreenOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ):(
                      <Link to={`/${allLocationNavigtion}#Split_View`} style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                        <ListItem key={headermenu} style={{padding:0}}>
                          <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>
                              <Typography
                              sx={{
                                px:1,
                                color:`${themeData?.header_font_color}`,
                                fontWeight:700,
                                fontFamily:`${themeData?.font_style}`,
                                textTransform:'capitalize',
                                fontSize:`${themeData?.font_size}px`,
                                display:'flex',
                                alignItems:'center',
                                backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                              }}
                              >
                                {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                  <React.Fragment>
                                      <SplitscreenOutlinedIcon sx={{mr:1}}/>
                                      {headermenu.display_title}
                                  </React.Fragment>
                                ) : null}
                                {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                  <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                    <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                      <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgbamin(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                        <SplitscreenOutlinedIcon 
                                          sx={{
                                            //color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                      {headermenu.display_title}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : null}
                {headermenu.headermenu_id == 10 && headermenu.url != '' ? (
                  <>
                    <a href={headermenu.url} target='blank' style={{textDecoration:'none'}} >
                      <ListItem key={headermenu} style={{padding:0}}>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                          <ListItemText>
                            <Typography
                            sx={{
                              px:1,
                              color:`${themeData?.header_font_color}`,
                              fontWeight:700,
                              fontFamily:`${themeData?.font_style}`,
                              textTransform:'capitalize',
                              fontSize:`${themeData?.font_size}px`,
                              display:'flex',
                              alignItems:'center',
                              backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                            }}
                            >
                              {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                                <React.Fragment>
                                    <FlatwareOutlinedIcon sx={{mr:1}}/>
                                    {headermenu.display_title}
                                </React.Fragment>
                              ) : null}
                              {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                                <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                  <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                    <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                      <FlatwareOutlinedIcon 
                                        sx={{
                                          //color:`${themeData?.header_font_color}`,
                                          fontWeight:700,
                                          fontFamily:`${themeData?.font_style}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                  <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                    {headermenu.display_title}
                                  </Box>
                                </Box>
                              ) : null}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </a>                    
                  </>
                ) : null}
                {headermenu.headermenu_id == 102 ? (
                  <a href='#Contact' style={{textDecoration:'none'}} onClick={mainTheme.layout == 2 ? handleDrawerToggle :toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                    <ListItem key={headermenu} style={{padding:0}}>
                      <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText>
                          <Typography
                          sx={{
                            px:1,
                            color:`${themeData?.header_font_color}`,
                            fontWeight:700,
                            fontFamily:`${themeData?.font_style}`,
                            textTransform:'capitalize',
                            fontSize:`${themeData?.font_size}px`,
                            display:'flex',
                            alignItems:'center',
                            backgroundColor:mainTheme.layout == 4 || mainTheme.layout == 1 ? themeData?.header_color:'transparent',
                          }}
                          >
                            {mainTheme.layout == 2 || mainTheme.layout == 3 || mainTheme.layout == 5 ? (
                              <React.Fragment>
                                  <CallOutlinedIcon sx={{mr:1}}/>
                                  {headermenu.display_title}
                              </React.Fragment>
                            ) : null}
                            {mainTheme.layout == 4 || mainTheme.layout == 1 ? (
                              <Box sx={{position:'relative',height:'60px',width:'100%'}}>
                                <Box sx={{position:'absolute',top:'-15px',left:0,right:0,margin:'auto',height:'45px',width:'45px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#fff',borderRadius:'50%'}}>
                                  <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                    <CallOutlinedIcon 
                                      sx={{
                                        //color:`${themeData?.header_font_color}`,
                                        fontWeight:700,
                                        fontFamily:`${themeData?.font_style}`,
                                        textTransform:'capitalize',
                                        fontSize:`${themeData?.font_size}px`,
                                        width:`${themeData?.font_size}px`,height:`${themeData?.font_size}px`
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                                <Box sx={{position:'absolute',bottom:'2px',left:0,right:0,margin:'auto'}}>
                                  {headermenu.display_title}
                                </Box>
                              </Box>
                            ) : null}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </a>
                ) : null}
                <Outlet />
              </>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  if (loading == false && substoredEncryptedKey == suboriginalKey) {
    return (
      <React.Fragment>
        {substoredEncryptedKey == suboriginalKey && (
          <React.Fragment>
            <AppDownload />
            {getLocation?.promotion_status === "active" && isPromotionVisible && (
              <Box sx={{backgroundColor:`${getLocation.promotion_bg}`,position:'relative',height:'40px'}}>
                <Box sx={{backgroundColor:`${getLocation.promotion_bg}`,position:`${appBarPosition}`,top:0,display:'flex',alignItems:'center',height:'40px',width:'100%',zIndex:1001}}>
                  <Marquee pauseOnHover={true}><Typography sx={{color:'#fff',mx:1}}>New Offer - </Typography><Typography dangerouslySetInnerHTML={{ __html: getLocation.promotion_description }} /></Marquee>
                  <Box 
                  sx={{
                    backgroundColor:`${getLocation.promotion_bg}`,
                    position:'absolute',
                    zIndex:9,
                    right:'0px',
                    top:'0px',
                    bottom:'0px',
                    padding:0,
                    margin:0,
                    height:'40px',
                    width:'50px'
                  }}
                  ></Box>
                  {mainTheme.layout == 1 ? (
                      <Button sx={{
                          position:'absolute',
                          zIndex:10,
                          right:'5px',
                          top:'5px',
                          borderRadius:'5px',
                          padding:0,
                          margin:0,
                          maxWidth:'30px',
                          minWidth:'30px',
                          width:'30px',
                          height:'30px',
                          boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                      }} 
                      onClick={togglePromotionVisibility} 
                      color='error' 
                      variant='contained'>
                          <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                      </Button>
                  ) : null}
                  {mainTheme.layout == 2 ? (
                    <Button sx={{
                        position:`${appBarPosition}`,
                        zIndex:10,
                        right:'5px',
                        borderRadius:'50%',
                        maxWidth:'30px',
                        minWidth:'30px',
                        padding:0,
                        margin:0,
                        width:'30px',
                        height:'30px',
                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={togglePromotionVisibility} 
                    color='error' 
                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                  ) : null}
                  {mainTheme.layout == 3 ? (
                    <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'5px',
                      padding:0,
                      margin:0,
                      maxWidth:'30px',
                      minWidth:'30px',
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={togglePromotionVisibility} 
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                  ) : null}
                  {mainTheme.layout == 4 ? (
                    <Button sx={{
                      position:`${appBarPosition}`,
                      zIndex:10,
                      right:'5px',
                      borderRadius:'50%',
                      maxWidth:'30px',
                      minWidth:'30px',
                      padding:0,
                      margin:0,
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={togglePromotionVisibility} 
                    color='error' 
                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                  ) : null}
                  {mainTheme.layout == 5 ? (
                    <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'5px',
                      padding:0,
                      margin:0,
                      maxWidth:'30px',
                      minWidth:'30px',
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={togglePromotionVisibility} 
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                  ) : null}
                </Box>
              </Box>
            )}
            <Box sx={{ display: 'flex',transition:'all 0.5s ease-in-out'}}>
              <CssBaseline />
                <ElevationScroll>
                  <AppBar style={{
                        backgroundColor:mainTheme.layout == 1 ? themeData?.header_color :`${appBarColor}`,
                        transition:'all 0.3s ease-in-out',
                        position:`${appBarPosition}`,
                        top:`${appBarPositionTop}`,
                        height:windowWidth < 600 ? '56px' : 'auto',
                      }} 
                      component="nav" 
                      className='navbar_desktop'>
                        {mainTheme.layout == 1 && (
                          <Box
                          sx={{display:`${appBarTopLogo}`,padding:'0 24px' , textAlign:'center',position:'absolute',width:'100%',height:'60px',top:`${appBarPositionTop}` ,backgroundColor:themeData?.header_color}}
                          >
                            <Box sx={{padding:'0 24px' , textAlign:'center',position:'relative',width:'100%',height:'60px',}}>
                                {alldata && alldata.user && (
                                  <Box sx={{position:'absolute',top:'18px',width:'100%'}}>
                                    <Box sx={{width:'fit-content',margin:'auto',p:1,borderRadius:'50%',backgroundColor:themeData?.header_color}}>
                                      {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                        <a href="#">
                                          <img
                                          width={80}
                                          src={`${alldata.user?.image}`}
                                          alt={'Logo Not Found'}
                                          loading="lazy"
                                          style={{display:`${appBarTopLogo}`,margin:'auto',transition:'all 0.3s ease-in-out',boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px',borderRadius:'50%'}}
                                          />
                                        </a>
                                      ):(
                                        <Link to={`/${allLocationNavigtion}#`}>
                                          <img
                                          width={80}
                                          src={`${alldata.user?.image}`}
                                          alt={'Logo Not Found'}
                                          loading="lazy"
                                          style={{display:`${appBarTopLogo}`,margin:'auto',transition:'all 0.3s ease-in-out',boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px',borderRadius:'50%'}}
                                          />
                                        </Link>
                                      )}
                                    </Box>
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        )}
                        {mainTheme.layout == 2 && (
                          <Box
                          sx={{padding:'0 24px' , textAlign:'center'}}
                          >
                            <React.Fragment>
                                {alldata && alldata.user && (
                                  <React.Fragment>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#">
                                        <img
                                        //width={80}
                                        src={`${alldata.user?.image}`}
                                        alt={'Logo Not Found'}
                                        loading="lazy"
                                        style={{display:`${appBarTopLogo}`,margin:'auto',transition:'all 0.3s ease-in-out'}}
                                        />
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#`}>
                                        <img
                                        //width={80}
                                        src={`${alldata.user?.image}`}
                                        alt={'Logo Not Found'}
                                        loading="lazy"
                                        style={{display:`${appBarTopLogo}`,margin:'auto',transition:'all 0.3s ease-in-out'}}
                                        />
                                      </Link>
                                    )}
                                  </React.Fragment>
                                )}
                            </React.Fragment>
                          </Box>
                        )}
                      <Toolbar sx={{ justifyContent:"space-between" ,position:'relative', width:mainTheme.layout == 2 ? '100%':mainTheme.layout == 3 || mainTheme.layout == 4 ? appBarWidthTheme3 :'100%',margin:'auto',transition:'all 0.5s ease-in-out'}}>
                          <Box>
                            {alldata && alldata.user && (
                              <>
                                {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                  <Box sx={{display:'flex',alignItems:'center',border:windowWidth < 600 ? mainTheme.layout == 5 ? '1px solid #000' : '' :'',borderRadius:'50px'}}>
                                    {windowWidth < 600 && (
                                      mainTheme.layout == 5 && (
                                        <>
                                          {['bottom'].map((anchor) => (
                                            <React.Fragment key={anchor}>
                                              <IconButton onClick={toggleDrawer(anchor, true)}>
                                                <MenuIcon sx={{color:themeData?.header_font_color}}/>
                                              </IconButton>
                                              <SwipeableDrawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                                onOpen={toggleDrawer(anchor, true)}
                                                className='drawer_3'
                                              >
                                                {drawer}
                                              </SwipeableDrawer>
                                            </React.Fragment>
                                          ))}
                                        </>
                                      )
                                    )}
                                    <a href="#" style={{display:'flex',alignItems:'center',border:windowWidth > 600 && mainTheme.layout == 5 ? '3px solid' : '',borderColor:`${themeData?.button_color}`,borderRadius:'50px'}}>
                                      <img
                                      //width={windowWidth < 600 ? 90 : 70}
                                      src={`${alldata.user?.image}`}
                                      alt={'Logo Not Found'}
                                      loading="lazy"
                                      style={{display:mainTheme.layout == 2 || mainTheme.layout == 1 ? `${appBarLogo}`:'block',transition:'all 0.3s ease-in-out',borderRadius:mainTheme.layout == 5 ?'50px' : ''}}
                                      />
                                    </a>
                                  </Box>
                                ):(
                                  <Box sx={{display:'flex',alignItems:'center',border:windowWidth < 600 ? '1px solid #000':''}}>
                                    {windowWidth < 600 && (
                                      mainTheme.layout == 5 && (
                                        <>
                                          {['bottom'].map((anchor) => (
                                            <React.Fragment key={anchor}>
                                              <IconButton onClick={toggleDrawer(anchor, true)}>
                                                <MenuIcon sx={{color:themeData?.header_font_color}}/>
                                              </IconButton>
                                              <SwipeableDrawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                                onOpen={toggleDrawer(anchor, true)}
                                                className='drawer_3'
                                              >
                                                {drawer}
                                              </SwipeableDrawer>
                                            </React.Fragment>
                                          ))}
                                        </>
                                      )
                                    )}
                                    <Link to={`/${allLocationNavigtion}#`} style={{display:'flex',alignItems:'center',borderRadius:'50px',border:windowWidth > 600 && mainTheme.layout == 5 ? '3px solid' : '',borderColor:`${themeData?.button_color}`}}>
                                      <img
                                      //width={windowWidth < 600 ? 90 : 70}
                                      src={`${alldata.user?.image}`}
                                      alt={'Logo Not Found'}
                                      loading="lazy"
                                      style={{display:mainTheme.layout == 2 || mainTheme.layout == 1 ? `${appBarLogo}`:'block',transition:'all 0.3s ease-in-out',borderRadius:mainTheme.layout == 5 ?'50px' : '',width:`${appLogoTheme2}`}}
                                      //style={{display:`${appBarLogo}`,transition:'all 0.3s ease-in-out'}}
                                      />
                                    </Link>
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                          <Box className='spareter_none' sx={{ display: { xs: 'none', sm: 'block' },textAlign:'center',width:{sm:'65%',md:'80%',xs:'90%'}}}>
                            {headermenu && headermenu?.map(headermenu => (
                              <>
                                {headermenu.headermenu_id == 100 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#">
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
    
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 1 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#gallery">
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#gallery`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 2 && (
                                  <>
                                  {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                    <a href="#Our_story">
                                      <Button key={headermenu} sx={{borderRadius:'0'}}>
                                        <Typography 
                                          sx={{
                                            borderLeft:`2px solid ${themeData?.header_font_color}`,
                                            px:1,
                                            color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            '&:hover':{
                                              backgroundColor:`${themeData?.header_font_color}`,
                                              color:`${themeData?.header_color}`,
                                              borderRadius:'5px',
                                            }
                                            }}>
                                          {headermenu.display_title}
                                        </Typography>
                                      </Button>
                                    </a>
                                  ):(
                                    <Link to={`/${allLocationNavigtion}#Our_story`}>
                                      <Button key={headermenu} sx={{borderRadius:'0'}}>
                                        <Typography 
                                          sx={{
                                            borderLeft:`2px solid ${themeData?.header_font_color}`,
                                            px:1,
                                            color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            '&:hover':{
                                              backgroundColor:`${themeData?.header_font_color}`,
                                              color:`${themeData?.header_color}`,
                                              borderRadius:'5px',
                                            }
                                            }}>
                                          {headermenu.display_title}
                                        </Typography>
                                      </Button>
                                    </Link>
                                  )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 3 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#News_letter">
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#News_letter`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 4 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#Our_blog">
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#Our_blog`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 5 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href="#Testimonials">
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#Testimonials`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 6  && webLocationLength > 1 && (
                                  <Button key={headermenu} sx={{borderRadius:'0'}}>
                                      <Link to={`/${allLocationNavigtion}/location`} style={{textDecoration:'none'}}>
                                      <Typography 
                                        sx={{
                                          borderLeft:`2px solid ${themeData?.header_font_color}`,
                                          px:1,
                                          color:`${themeData?.header_font_color}`,
                                          fontWeight:700,
                                          display:'flex',fontFamily:`${themeData?.font_style}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          '&:hover':{
                                            backgroundColor:`${themeData?.header_font_color}`,
                                            color:`${themeData?.header_color}`,
                                            borderRadius:'5px',
                                          }
                                          }}>
                                          {headermenu.display_title}
                                      </Typography>
                                    </Link>
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton sx={{padding:0}} {...bindTrigger(popupState)}>
                                            <AddTwoToneIcon sx={{
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              display:'flex',fontFamily:`${themeData?.font_style}`}}/>
                                          </IconButton>
                                          <Menu {...bindMenu(popupState)} className='locationHeaderManu'>
                                          {headermenu.data?.map((locationHeaderManu)=>(
                                            <>
                                              <a href={`/${locationHeaderManu.url}`} style={{textDecoration:'none'}}>
                                                <MenuItem onClick={popupState.close} sx={{
                                                    backgroundColor:'#1d1f24',
                                                    borderLeft:`2px solid ${themeData?.header_font_color}`,
                                                    borderRadius:'5px',
                                                    my:1,
                                                    px:1,
                                                    color:`#fff`,
                                                    fontWeight:700,
                                                    fontFamily:`${themeData?.font_style}`,
                                                    textTransform:'capitalize',
                                                    fontSize:`${themeData?.font_size}px`,
                                                    '&:hover':{
                                                      backgroundColor:`#efdc32`,
                                                      color:`#fff`,
                                                      borderRadius:'5px',
                                                    }
                                                }}>{locationHeaderManu.title}</MenuItem>
                                              </a>
                                            </>
                                          ))}
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Button>
                                )}
                                {headermenu.headermenu_id == 7 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href='#Reservation'>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                    <Link to={`/${allLocationNavigtion}#Reservation`}>
                                      <Button key={headermenu} sx={{borderRadius:'0'}}>
                                        <Typography 
                                          sx={{
                                            borderLeft:`2px solid ${themeData?.header_font_color}`,
                                            px:1,
                                            color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            '&:hover':{
                                              backgroundColor:`${themeData?.header_font_color}`,
                                              color:`${themeData?.header_color}`,
                                              borderRadius:'5px',
                                            }
                                            }}>
                                          {headermenu.display_title}
                                        </Typography>
                                      </Button>
                                    </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 8 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href='#Slide_Banner'>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#Slide_Banner`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 101 && res_id != '' && res_id && (
                                  <Link to={`/${allLocationNavigtion}/menu`} >
                                    <Button key={headermenu} sx={{borderRadius:'0'}}>
                                      <Typography 
                                        sx={{
                                          borderLeft:`2px solid ${themeData?.header_font_color}`,
                                          px:1,
                                          color:`${themeData?.header_font_color}`,
                                          fontWeight:700,
                                          fontFamily:`${themeData?.font_style}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          '&:hover':{
                                            backgroundColor:`${themeData?.header_font_color}`,
                                            color:`${themeData?.header_color}`,
                                            borderRadius:'5px',
                                          }
                                          }}>
                                        {headermenu.display_title}
                                      </Typography>
                                    </Button>
                                  </Link>
                                )}
                                {headermenu.headermenu_id == 9 && (
                                  <>
                                    {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                      <a href='#Split_View'>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </a>
                                    ):(
                                      <Link to={`/${allLocationNavigtion}#Split_View`}>
                                        <Button key={headermenu} sx={{borderRadius:'0'}}>
                                          <Typography 
                                            sx={{
                                              borderLeft:`2px solid ${themeData?.header_font_color}`,
                                              px:1,
                                              color:`${themeData?.header_font_color}`,
                                              fontWeight:700,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              '&:hover':{
                                                backgroundColor:`${themeData?.header_font_color}`,
                                                color:`${themeData?.header_color}`,
                                                borderRadius:'5px',
                                              }
                                              }}>
                                            {headermenu.display_title}
                                          </Typography>
                                        </Button>
                                      </Link>
                                    )}
                                  </>
                                )}
                                {headermenu.headermenu_id == 10 && headermenu.url != '' && (
                                  <>                                  
                                    <a href={headermenu.url} target='blank'>
                                      <Button key={headermenu} sx={{borderRadius:'0'}}>
                                        <Typography 
                                          sx={{
                                            borderLeft:`2px solid ${themeData?.header_font_color}`,
                                            px:1,
                                            color:`${themeData?.header_font_color}`,
                                            fontWeight:700,
                                            fontFamily:`${themeData?.font_style}`,
                                            textTransform:'capitalize',
                                            fontSize:`${themeData?.font_size}px`,
                                            '&:hover':{
                                              backgroundColor:`${themeData?.header_font_color}`,
                                              color:`${themeData?.header_color}`,
                                              borderRadius:'5px',
                                            }
                                            }}>
                                          {headermenu.display_title}
                                        </Typography>
                                      </Button>
                                    </a>                                    
                                  </>
                                )}
                                {headermenu.headermenu_id == 102 && (
                                  <a href='#Contact'>
                                    <Button key={headermenu} sx={{borderRadius:'0'}}>
                                      <Typography 
                                        sx={{
                                          borderLeft:`2px solid ${themeData?.header_font_color}`,
                                          px:1,
                                          color:`${themeData?.header_font_color}`,
                                          fontWeight:700,
                                          fontFamily:`${themeData?.font_style}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          '&:hover':{
                                            backgroundColor:`${themeData?.header_font_color}`,
                                            color:`${themeData?.header_color}`,
                                            borderRadius:'5px',
                                          }
                                          }}>
                                        {headermenu.display_title}
                                      </Typography>
                                    </Button>
                                  </a>
                                )}
                                <Outlet />
                              </>
                            ))}
                          </Box>
                          <Box sx={{display:'flex',alignItems:'center'}}>
                              {mainTheme?.layout == 5 && (
                                <>
                                  <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled button group"
                                  >
                                    <Button
                                      sx={{
                                        my:1.5,
                                        width:'-webkit-fill-available',
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        }
                                      }}
                                      href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                      onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}
                                    >Order Online</Button>
                                    <Button onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}  href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                  </ButtonGroup>
                                  <ButtonGroup
                                    disableElevation
                                    variant="contained"
                                    aria-label="Disabled button group"
                                    sx={{
                                      display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'block' : 'none' : 'none',
                                      position:'fixed',
                                      top:'50%',
                                      right:'10px',
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        my:1.5,
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        }
                                      }}
                                      href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                      onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}
                                    >Order Online</Button>
                                    <Button onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}  href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                  </ButtonGroup>
                                </>
                              )}
                              {mainTheme.layout == 1 ? (
                                <>
                                  <Button 
                                    sx={{
                                      right:'10px',
                                      boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: '50px',
                                      fontWeight:600,
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: '50px',
                                      }
                                    }} 
                                    href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      Order Online
                                  </Button>
                                    <Button 
                                      sx={{
                                        display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'flex' : 'none' : 'none',
                                        position:'fixed',
                                        top:'50%',
                                        right:'10px',
                                        boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius: '50px',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius: '50px',
                                        }
                                      }} 
                                      href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                      onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                        Order Online
                                    </Button>
                                </>
                              ) : ''}
                              {mainTheme.layout == 2 ? (
                                <>
                                  {windowWidth > 600 && (
                                   <> 
                                  <Button 
                                    sx={{
                                      right:'10px',
                                      // boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: '8px',
                                      fontWeight:600,
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: '8px',
                                      }
                                    }}                                     
                                      href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}                                    
                                      onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      Order Online
                                  </Button>
                                    <Button 
                                      sx={{
                                        display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'flex' : 'none' : 'none',
                                        position:'fixed',
                                        top:'50%',
                                        right:'10px',
                                        boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                        backgroundColor:`${themeData?.button_color}`,
                                        color:`${themeData?.button_font_color}`,
                                        borderRadius: '50px',
                                        fontWeight:600,
                                        '&:hover': {
                                          backgroundColor:`${themeData?.button_font_color}`,
                                          color:`${themeData?.button_color}`,
                                          border:`1px solid ${themeData?.button_color}`,
                                          borderRadius: '50px',
                                        }
                                      }} 
                                      href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                      onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                        Order Online
                                    </Button>
                                    </>
                                  )}
                                </>
                              ) : ''}
                              {mainTheme?.layout == 3 ? (
                                <>
                                  <Button 
                                    sx={{
                                      display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'none' : 'flex' : 'flex',
                                      position:mainTheme.layout == 2 ? {xs:'fixed',sm:'absolute',md:'absolute',lg:'absolute'}:mainTheme.layout == 3 ? {xs:'absolute',sm:'absolute',md:'absolute',lg:'absolute'}:'',
                                      bottom:mainTheme.layout == 2 ? {xs:'50px',sm:'unset'}:mainTheme.layout == 3 ? {xs:'unset',sm:'unset'} :'',
                                      right:'10px',
                                      boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      fontWeight:600,
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      }
                                    }} 
                                    href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      Order Online
                                  </Button>
                                  <Button 
                                    sx={{
                                      display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'flex' : 'none' : 'none',
                                      position:'fixed',
                                      bottom: mainTheme?.layout == 2 ? windowWidth < 786 ? '10px' :'50%':'50%',
                                      right: '5%',
                                      left: mainTheme?.layout == 2 ? windowWidth < 786 ? '5%' : 'unset' :'unset',
                                      width: mainTheme?.layout == 2 ?  windowWidth < 786 ? '90%':'fit-content' : 'fit-content',
                                      px:3,
                                      py:1,
                                      boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: mainTheme?.layout == 2 ?  windowWidth < 786 ? '50px' : '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      fontWeight:600,
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: mainTheme?.layout == 2 ? windowWidth < 786 ? '50px': '8px':mainTheme?.layout == 3 ?'4px' :'',
                                      }
                                    }} 
                                    href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      Order Online
                                  </Button>
                                </>
                              ) : null}
                              {mainTheme.layout == 4 ? (
                                <>
                                  <Button 
                                    sx={{
                                      position:mainTheme.layout == 2 ? {xs:'fixed',sm:'absolute',md:'absolute',lg:'absolute'}:mainTheme.layout == 3 ? {xs:'absolute',sm:'absolute',md:'absolute',lg:'absolute'}:'',
                                      bottom:mainTheme.layout == 2 ? {xs:'50px',sm:'unset'}:mainTheme.layout == 3 ? {xs:'unset',sm:'unset'} :'',
                                      right:'10px',
                                      boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      fontWeight:600,
                                      width:"max-content",
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        '& .ads-click-icon': {
                                          color: `${themeData?.button_color}`,
                                        }
                                      }
                                    }} 
                                    href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:`${themeData?.button_font_color}`,mx:1}} /> : ''}
                                      Order Online
                                  </Button>
                                  <Button 
                                    sx={{
                                      display:trigger ? currentUrl.pathname === `/${allLocationNavigtion}/menu` || currentUrl.pathname === `/${allLocationNavigtion}/menuitem/${lastPart}` ? 'flex':'none':'none',
                                      position:'fixed',
                                      top:'50%',
                                      right:'10px',
                                      boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                      backgroundColor:`${themeData?.button_color}`,
                                      color:`${themeData?.button_font_color}`,
                                      borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                      fontWeight:600,
                                      '&:hover': {
                                        backgroundColor:`${themeData?.button_font_color}`,
                                        color:`${themeData?.button_color}`,
                                        border:`1px solid ${themeData?.button_color}`,
                                        borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                        '& .ads-click-icon': {
                                          color: `${themeData?.button_color}`,
                                        }
                                      }
                                    }} 
                                    href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                                    onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                                      {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:`${themeData?.button_font_color}`,mx:1}} /> : ''}
                                      Order Online
                                  </Button>
                                </>
                              ) : null }
                          </Box>
                          {mainTheme.layout == 1 && (
                            <React.Fragment>
                              {windowWidth < 600 && (
                                <div style={{backgroundColor:`${themeData?.header_color}`,color:`${themeData?.header_font_color}`}}>
                                  {['bottom'].map((anchor) => (
                                    <React.Fragment key={anchor}>
                                      <IconButton sx={{color:themeData?.header_font_color }} onClick={toggleDrawer(anchor, true)}>
                                        <MenuOpenRoundedIcon />
                                      </IconButton>
                                      <SwipeableDrawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                        onOpen={toggleDrawer(anchor, true)}
                                        className='drawer_3'
                                      >
                                        {drawer}
                                      </SwipeableDrawer>
                                    </React.Fragment>
                                  ))}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                          {mainTheme.layout == 2 && (
                            <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { sm: 'none' } , color:themeData?.header_font_color }}>
                                <MenuIcon sx={{fontSize:`${appIconTheme2}`,marginRight:'-10px'}}/>
                            </IconButton>
                          )}
                      </Toolbar>
                      {mainTheme.layout == 2 && (
                        <>
                          {windowWidth < 600 && (
                            <>
                             <Toolbar sx={{position:'fixed',bottom:'0px',left:0,right:0,backgroundColor:`${themeData?.header_color} !important`,width:'100%',margin:'auto',p:0,height:'65px',transition:'bottom 0.3s ease-in-out'}}>
                              <Box sx={{position:'relative',backgroundColor:'#fff',width:'100%',height:'60px',display: "flex",justifyContent:"center"}}>
                                <a href={`tel:${getLocation?.phone}`} style={{textDecoration:'none'}}>
                                  <Typography variant='h6'
                                    sx={{
                                      // position: 'absolute',
                                      // zIndex: 1,
                                      // top: 0,
                                      // left:'3%',                                    
                                      margin: '10px 0px 0px',
                                      textAlign:'center',
                                      fontWeight:700,
                                      fontFamily:`${themeData?.font_style}`,
                                      textTransform:'capitalize',
                                      borderRadius: '50px',
                                      padding:'5px 10px',
                                      color:`${themeData?.button_font_color} !important`,
                                      backgroundColor:`${themeData?.button_color}`,
                                      fontSize:`${themeData?.font_size}px`,
                                      }}>Contact</Typography>
                                </a>
                                {/* href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} */}
                                {webLocationLength == 1 &&(
                                  <>
                                    <a href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} style={{textDecoration:'none'}}>     
                                      <Typography variant='h6' 
                                        sx={{
                                          // position: 'absolute',
                                          // zIndex: 1,
                                          // top: 0,
                                          // left: -30,
                                          // right:0,                         
                                          textAlign:'center',                                
                                          margin: '10px 8px 0px',
                                          fontWeight:700,
                                        // width: 'fit-content',
                                          fontFamily:`${themeData?.font_style}`,
                                          borderRadius: '50px',
                                          padding:'5px 10px',
                                          backgroundColor:`${themeData?.button_color}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          color:`${themeData?.button_font_color} !important`,
                                          }}>Order Online</Typography>
                                    </a>
                                  </>
                                )}
                                 {webLocationLength > 1 && (
                                  <>
                                    <a onClick={webLocationLength > 1 ? handleClickOpenOrder : ''} style={{textDecoration:'none'}}>     
                                      <Typography variant='h6' 
                                        sx={{
                                          // position: 'absolute',
                                          // zIndex: 1,
                                          // top: 0,
                                          // left: -30,
                                          // right:0,                         
                                          textAlign:'center',                                
                                          margin: '10px 8px 0px',
                                          fontWeight:700,
                                        // width: 'fit-content',
                                          fontFamily:`${themeData?.font_style}`,
                                          borderRadius: '50px',
                                          padding:'5px 10px',
                                          backgroundColor:`${themeData?.button_color}`,
                                          textTransform:'capitalize',
                                          fontSize:`${themeData?.font_size}px`,
                                          color:`${themeData?.button_font_color} !important`,
                                          }}>Order Online</Typography>
                                    </a>
                                  </>
                                )}
                                {headermenu.map(headermenu => (
                                  <>
                                    {headermenu.headermenu_id == 7 ? (
                                      <>
                                      {currentUrl.pathname == `/${allLocationNavigtion}` ? (
                                        <a href='#Reservation' style={{textDecoration:'none'}}>
                                          <Typography variant='h6'
                                            sx={{
                                              // position: 'absolute',
                                              // zIndex: 1,
                                              // top: 0,
                                              // right:'3%',
                                              margin: '10px 0px 0px',
                                              fontWeight:700,
                                              borderRadius: '50px',
                                              padding:'5px 10px',
                                              backgroundColor:`${themeData?.button_color}`,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              color:`${themeData?.button_font_color} !important`,
                                              }}>Reservation</Typography>
                                          </a>
                                      ):(
                                        <Link to={`/${allLocationNavigtion}#Reservation`}>
                                          <Typography variant='h6'
                                            sx={{
                                              // position: 'absolute',
                                              // zIndex: 1,
                                              // top: 0,
                                              // right:'3%',
                                              margin: '10px 0px 0px',
                                              fontWeight:700,
                                              borderRadius: '50px',
                                              padding:'5px 10px',
                                              backgroundColor:`${themeData?.button_color}`,
                                              fontFamily:`${themeData?.font_style}`,
                                              textTransform:'capitalize',
                                              fontSize:`${themeData?.font_size}px`,
                                              color:`${themeData?.button_font_color} !important`,
                                              }}>Reservation</Typography>                                          
                                        </Link>          
                                      )}
                                      </>                     
                                    ) : null} 
                                  </>
                                ))} 
                              </Box>
                            </Toolbar>
                            </>
                          )}
                        </>
                      )}
                      {mainTheme.layout == 3 && (
                        <>
                          {windowWidth < 600 && (
                              <Toolbar sx={{position:'fixed',bottom:'10px',left:0,right:0,backgroundColor:'#fff',width:'90%',margin:'auto',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',borderRadius:'5px',transition: 'bottom 0.3s ease-in-out',}} >
                                  <IconButton href={`tel:${getLocation?.phone}`} sx={{display:'flex',flexDirection:'column'}}>
                                    <PhoneOutlinedIcon sx={{color:themeData?.header_color}} />
                                    <Typography variant='h6' sx={{
                                      fontWeight:700,
                                      fontFamily:`${themeData?.font_style}`,
                                      textTransform:'capitalize',
                                      fontSize:`${themeData?.font_size}px`,
                                      color:`${themeData?.header_color} !important`
                                    }}>Contact</Typography>
                                  </IconButton>
                                  <StyledFab sx={{backgroundColor:`${themeData?.header_color} !important`}}>
                                    <div style={{backgroundColor:`${themeData?.header_color} !important`}}>
                                      {['bottom'].map((anchor) => (
                                        <React.Fragment key={anchor}>
                                          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{color:themeData?.header_font_color}}/></Button>
                                          <SwipeableDrawer
                                            anchor={anchor}
                                            open={state[anchor]}
                                            onClose={toggleDrawer(anchor, false)}
                                            onOpen={toggleDrawer(anchor, true)}
                                            className='drawer_3'
                                          >
                                            {drawer}
                                          </SwipeableDrawer>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </StyledFab>
                                  <Box sx={{ flexGrow: 1 }} />
                                  <IconButton sx={{display:'flex',flexDirection:'column'}} onClick={webLocationLength > 1 ? handleClickOpenOrder : ''} href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}>
                                    <ShoppingCartOutlinedIcon sx={{color:themeData?.header_color}}/>
                                    <Typography variant='h6' sx={{
                                      fontWeight:700,
                                      fontFamily:`${themeData?.font_style}`,
                                      textTransform:'capitalize',
                                      fontSize:`${themeData?.font_size}px`,
                                      color:`${themeData?.header_color} !important`}}>Order Online</Typography>
                                  </IconButton>
                              </Toolbar>
                          )}
                        </>
                      )}
                      {mainTheme.layout == 4 && (
                        <>
                          {windowWidth < 600 && (
                             <Toolbar sx={{position:'fixed',bottom:'10px',left:0,right:0,backgroundColor:`${themeData?.header_color} !important`,width:'90%',margin:'auto',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',borderRadius:'20px',p:0,height:'90px',transition:'bottom 0.3s ease-in-out'}}>
                              <Box sx={{position:'relative',backgroundColor:'#fff',width:'100%',height:'60px',marginTop:'30px',borderRadius:'5px 5px 20px 20px'}}>
                                <StyledFabContact sx={{backgroundColor:`${themeData?.header_color} !important`,boxShadow:'unset'}}>
                                  <IconButton href={`tel:${getLocation?.phone}`} sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}>
                                    <PhoneOutlinedIcon sx={{color:themeData?.header_font_color}} />
                                  </IconButton>
                                </StyledFabContact>                                
                                <StyledFabMenu sx={{backgroundColor:`${themeData?.header_color} !important`,boxShadow:'unset'}}>
                                  <div style={{backgroundColor:`${themeData?.header_color} !important`}}>
                                    {['bottom'].map((anchor) => (
                                      <React.Fragment key={anchor}>
                                        <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}}  onClick={toggleDrawer(anchor, true)}>
                                          <MenuIcon sx={{color:themeData?.header_font_color}}/>
                                        </IconButton>
                                        <SwipeableDrawer
                                          anchor={anchor}
                                          open={state[anchor]}
                                          onClose={toggleDrawer(anchor, false)}
                                          onOpen={toggleDrawer(anchor, true)}
                                          className='drawer_3'
                                        >
                                          {drawer}
                                        </SwipeableDrawer>
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </StyledFabMenu>
                                <StyledFabOrder sx={{backgroundColor:`${themeData?.header_color} !important`,boxShadow:'unset'}}>
                                  <IconButton sx={{backgroundColor:'#fff',display:'flex',flexDirection:'column',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'}} onClick={webLocationLength > 1 ? handleClickOpenOrder : ''} href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}>
                                    <ShoppingCartOutlinedIcon sx={{color:themeData?.header_font_color}}/>
                                  </IconButton>
                                </StyledFabOrder>
                                <Typography variant='h6'
                                  sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: 30,
                                    left: '15%',
                                    margin: '0 auto',
                                    fontWeight:700,
                                    fontFamily:`${themeData?.font_style}`,
                                    textTransform:'capitalize',
                                    fontSize:`${themeData?.font_size}px`,
                                    color:`${themeData?.header_font_color} !important`
                                    }}>Contact</Typography>                               
                                <Typography variant='h6'
                                  sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: 30,
                                    left: 0,
                                    right:0,
                                    margin: '0 auto',
                                    textAlign:'center',
                                    fontWeight:700,
                                    fontFamily:`${themeData?.font_style}`,
                                    textTransform:'capitalize',
                                    fontSize:`${themeData?.font_size}px`,
                                    color:`${themeData?.header_font_color} !important`
                                    }}>Menu</Typography>
                               <Typography variant='h6' 
                                  sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: 30,
                                    right: '12%',                                   
                                    margin: '0 auto',
                                    fontWeight:700,
                                    fontFamily:`${themeData?.font_style}`,
                                    textTransform:'capitalize',
                                    fontSize:`${themeData?.font_size}px`,
                                    color:`${themeData?.header_font_color} !important`
                                    }}>Order Online</Typography>
                              </Box>
                            </Toolbar>
                          )}
                        </>
                      )}
                      {mainTheme.layout == 5 && (
                        <>
                          {windowWidth < 600 && (
                            <>                            
                            <Box sx={{position:'fixed',width:'60px',height:'60px',bottom:'0',left:'0',backgroundColor:'#0006',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'0 50% 0 0'}}>
                              <Box sx={{mx:1,width:'40px',height:'40px',borderRadius:'50%',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',backgroundColor:`${themeData?.header_color} !important`}}>
                                <IconButton href={`tel:${getLocation?.phone}`} >
                                  <PhoneOutlinedIcon sx={{color:themeData?.header_font_color}}/>
                                </IconButton>
                              </Box>
                            </Box>
                            <Box sx={{position:'fixed',width:'60px',height:'60px',bottom:'0',right:'0',backgroundColor:'#0006',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50% 0 0 0'}}>
                              <Box sx={{mx:1,width:'40px',height:'40px',borderRadius:'50%',boxShadow:'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',backgroundColor:`${themeData?.header_color} !important`}}>
                                <IconButton onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}  href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}>
                                  <ShoppingCartOutlinedIcon sx={{color:themeData?.header_font_color}}/>
                                </IconButton>
                              </Box>
                            </Box>
                            </>
                          )}
                        </>
                      )}
                  </AppBar>
                </ElevationScroll>
              <nav>
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth , backgroundColor:`${themeData?.header_color}`,
                    color:`${themeData?.header_font_color}`},
                  }}
                >
                  {drawer}
                </Drawer>
              </nav>
            </Box>
            <Dialog
                open={openOrder}
                onClose={handleCloseOrder}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              {mainTheme.layout == 1 ? (
                  <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'5px',
                      padding:0,
                      margin:0,
                      maxWidth:'30px',
                      minWidth:'30px',
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                  }} 
                  onClick={handleCloseOrder}
                  color='error' 
                  variant='contained'>
                      <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                  </Button>
              ) : null}
              {mainTheme.layout == 2 ? (
                <Button sx={{
                    position:'absolute',
                    zIndex:10,
                    right:'5px',
                    top:'5px',
                    borderRadius:'50%',
                    maxWidth:'30px',
                    minWidth:'30px',
                    padding:0,
                    margin:0,
                    width:'30px',
                    height:'30px',
                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                }} 
                onClick={handleCloseOrder}
                color='error' 
                variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
              ) : null}
              {mainTheme.layout == 3 ? (
                  <Button sx={{
                    position:'absolute',
                    zIndex:10,
                    right:'5px',
                    top:'5px',
                    borderRadius:'5px',
                    padding:0,
                    margin:0,
                    maxWidth:'30px',
                    minWidth:'30px',
                    width:'30px',
                    height:'30px',
                    boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                  }} 
                  onClick={handleCloseOrder}
                  color='error' 
                  variant='contained'>
                      <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                  </Button>
              ) : null}
              {mainTheme.layout == 4 ? (
                <Button sx={{
                  position:'absolute',
                  zIndex:10,
                  right:'5px',
                  top:'5px',
                  borderRadius:'50%',
                  maxWidth:'30px',
                  minWidth:'30px',
                  padding:0,
                  margin:0,
                  width:'30px',
                  height:'30px',
                  boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                }} 
                  onClick={handleCloseOrder}
                  color='error' 
                  variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
              ) : null}
              {mainTheme.layout == 5 ? (
                <Button sx={{
                  position:'absolute',
                  zIndex:10,
                  right:'5px',
                  top:'5px',
                  borderRadius:'5px',
                  padding:0,
                  margin:0,
                  maxWidth:'30px',
                  minWidth:'30px',
                  width:'30px',
                  height:'30px',
                  boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                }} 
                onClick={handleCloseOrder}
                color='error' 
                variant='contained'>
                    <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                </Button>
              ) : null}
                <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',borderBottom:'0.6px solid #eee'}}>
                  <FmdGoodTwoToneIcon sx={{width:'100px',height:'auto',fill:'red',stroke:'white'}}/>
                  <Typography sx={{fontWeight:700}} variant='h5'>Choose Location</Typography>
                  <Typography variant='p'>Powered by Eatstations</Typography>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {alldata && alldata.order_now_urls?.map((order_now_urls)=>(
                    <Box sx={{textAlign:'center',borderBottom:'0.4px solid #eee',py:2}}>
                      <Box sx={{display:'flex',alignItems:'center',m:1}}>
                        <Box sx={{backgroundColor:'#fdc51680',width:'50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',mx:1}}>
                          <FmdGoodTwoToneIcon sx={{fill:'white',stroke:'black'}}/>
                        </Box>
                        <Typography sx={{fontWeight:700,color:'black'}} variant='h5'>{order_now_urls?.title}</Typography>
                      </Box>
                      <address sx={{m:1}}>{order_now_urls?.address}</address>
                        {mainTheme?.layout == 5 && (
                          <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled button group"
                          >
                            <Button
                              sx={{
                                my:1.5,
                                backgroundColor:`${themeData?.button_color}`,
                                color:`${themeData?.button_font_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                fontWeight:600,
                                '&:hover': {
                                  backgroundColor:`${themeData?.button_font_color}`,
                                  color:`${themeData?.button_color}`,
                                  border:`1px solid ${themeData?.button_color}`,
                                  borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                }
                              }}
                              href={`${order_now_urls?.url}`}
                            >Order Online</Button>
                            <Button href={`${order_now_urls?.url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                          </ButtonGroup>
                        )}
                        {mainTheme.layout == 1 ? (
                          <Button 
                          sx={{
                            my:2,
                            backgroundColor:`${themeData?.button_color}`,
                            color:`${themeData?.button_font_color}`,
                            borderRadius:'50px',
                            fontWeight:600,
                            '&:hover': {
                              backgroundColor:`${themeData?.button_font_color}`,
                              color:`${themeData?.button_color}`,
                              border:`1px solid ${themeData?.button_color}`,
                              borderRadius:'50px',
                            }
                          }} 
                          href={`${order_now_urls?.url}`}
                        >
                          Order Online</Button>
                        ) : null }
                        {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                          <Button 
                            sx={{
                              my:2,
                              backgroundColor:`${themeData?.button_color}`,
                              color:`${themeData?.button_font_color}`,
                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                              fontWeight:600,
                              '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                '& .ads-click-icon': {
                                  color: `${themeData?.button_color}`,
                                }
                              }
                            }} 
                            href={`${order_now_urls?.url}`}
                          >
                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:`${themeData?.button_font_color}`,mx:1}} /> : ''}
                            Order Online</Button>
                        ) : null}
                      </Box>
                  ))}
                </DialogContentText>
                </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  else{
    return null;
  }
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
